.pages {
    padding-top: 20px;
    .addWrap {
        margin-bottom: 10px;
    }

    .datagrid {
        .cellAction {
            display: flex;
            align-items: center;

            gap: 15px;

            .icon {
                cursor: pointer;
            }
            a {
                display: flex;
                align-items: center;
            }
        }
    }
}
.modalButton {
    display: flex;
    justify-content: space-around;
    padding: 20px;
}
#modal-modal-title {
    text-align: center;
}
.modalInnerGroup {
    p {
        margin-top: 10px;
        display: flex;
        justify-content: space-between;
    }
}
