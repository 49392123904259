.block {
    margin-top: 20px;
    border: 0.5px solid black;
    padding: 10px;
    .actions {
        display: flex;
        align-items: center;
        gap: 5px;
        .blockIndex {
            font-weight: 600;
            flex: 24;
        }
        .action {
            border-radius: 6px;
            border: 1px solid black;
            background-color: rgba(85, 85, 85, 0.712);
            color: white;
            padding: 2px;
            font-size: 10px;
            text-transform: uppercase;
            cursor: pointer;
        }
        .action.active {
            background-color: rgba(81, 153, 142, 0.815);
        }
        .checkTdTh {
            margin-left: 10px;
        }
    }
    .buttons {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 10px;
        .iconWrapper {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .collapseIcon {
                font-size: 36px;
                color: green;
            }
        }
    }
    .inputGroup {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .uploadImage {
            width: 90%;
            padding: 5px;
            display: flex;
            align-items: center;
            img {
                width: 100px;
                margin-right: 10px;
            }
        }
    }
}
