.editPage {
    display: flex;

    .editPageContainer {
        flex: 6;

        .editPageWrap {
            -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
            box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
            padding: 20px;
            margin: 20px;

            .editPageTitle {
                font-weight: 500;
                color: black;
                margin-bottom: 10px;
                font-size: 18px;
            }
        }
    }
    .actionsWrap {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}
