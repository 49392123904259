.login {
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.loginWrapper {
    width: 100%;
    height: 100%;
    display: flex;
}

.loginLeft {
    background-image: url(/public/assets/welcome.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    flex: 3;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.loginRight {
    flex: 2;
    display: flex;
    flex-direction: column;

    align-items: center;
}
.loginLogo {
    width: 50px;
    height: 50px;
    background-color: rgb(219, 11, 108);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;

    font-size: 50px;
    font-weight: 800;

    margin-bottom: 10px;
    margin-top: 40px;
}
.loginIcon {
    width: 32px;
    height: 32px;
    color: white;
}

.loginBox {
    width: 90%;
    background-color: white;
    display: flex;
    flex-direction: column;
}

.spanError {
    font-size: 12px;
    margin-top: 10px;
    color: red;
}

.loginButton {
    height: 40px;
    border-radius: 5px;
    border: none;
    background-color: #1775ee;
    color: white;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    margin-top: 30px;
}

.loginButton:focus {
    outline: none;
}

.loginButton:disabled {
    cursor: not-allowed;
}

.loginForgot {
    color: #1775ee;
    margin-top: 10px;
}
.loginCopyright {
    margin-top: 50px;
    font-size: 14px;
    text-align: center;
}
