.navbar {
  height: 50px;
  display: flex;
  border-bottom: 0.5px solid rgb(228, 226, 226);
  align-items: center;
  font-size: 14px;
  color: #555;
  .wrapper {
    display: flex;
    align-items: center;
    padding: 20px;
    justify-content: space-between;
    width: 100%;
    .logo {
      display: flex;
      align-items: center;

      padding: 3px;
      font-style: 20px;
      color: blue;
    }
    .items {
      display: flex;
      align-items: center;
      .item {
        display: flex;
        align-items: center;
        margin-right: 20px;
        position: relative;
        cursor: pointer;
        li {
          display: flex;
          align-items: center;
          margin-right: 20px;
        }

        .icon {
          font-size: 20px;
        }
      }
    }
  }
}
